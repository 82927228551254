import ApiService from "@/core/services/ApiService";
import { AxiosResponse } from "axios";

const API_URL = "users";

class UserService {
  public static getCurrentUser(): Promise<AxiosResponse> {
    return ApiService.get(API_URL + "/me");
  }

  public static getAllUsers(searchUserFilter): Promise<AxiosResponse> {
    return ApiService.post(API_URL, searchUserFilter);
  }

  public static getMyActivityRoom(activityId): Promise<AxiosResponse> {
    return ApiService.get(API_URL + "/room/" + activityId);
  }
}

export default UserService;
