import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { IUser } from "@/core/data/user";
import JwtService from "@/core/services/JwtService";

export const useAuthStore = defineStore("auth", () => {
  const isAuthenticated = ref(!!JwtService.getToken());
  const user = ref<IUser>();
  const isTopicChange = ref<boolean>();
  const isModuleChange = ref<boolean>();

  const currentUser = computed(() => {
    return user.value;
  });
  const isUserAuthenticated = computed(() => isAuthenticated.value);

  const isTopicDataChange = computed(() => isTopicChange.value);

  const isModuleDataChange = computed(() => isModuleChange.value);

  function setAuth(token) {
    isAuthenticated.value = true;
    JwtService.saveToken(token);
  }

  function purgeAuth() {
    return new Promise<void>((resolve, reject) => {
      isAuthenticated.value = false;
      user.value = {} as IUser;
      JwtService.destroyToken();
      resolve();
    });
  }

  function setUser(userData) {
    return new Promise<void>((resolve, reject) => {
      user.value = userData;
      resolve();
    });
  }

  function setTopicDataChange(isChange) {
    isTopicChange.value = isChange;
  }

  function setModuleDataChange(isChange) {
    isModuleChange.value = isChange;
  }

  return {
    isAuthenticated,
    user,
    isTopicChange,
    isModuleChange,
    currentUser,
    isUserAuthenticated,
    setAuth,
    setUser,
    purgeAuth,
    isTopicDataChange,
    isModuleDataChange,
    setTopicDataChange,
    setModuleDataChange,
  };
});
