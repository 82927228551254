
import { defineComponent, onMounted } from "vue";
import GoogleService from "@/core/services/GoogleService";
import { useRoute, useRouter } from "vue-router";
import UserService from "@/core/services/UserService";
import { useAuthStore } from "@/store/useAuthStore";
export default defineComponent({
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const authStore = useAuthStore();

    const onGooglePostLogin = (authCode: any) => {
      GoogleService.postLogin(authCode)
        .then((response) => {
          authStore.setAuth(response.data);
          UserService.getCurrentUser().then((res) => {
            authStore.setUser(res.data).then(() => {
              // Go to page after successfully login

              router.push({ name: "View All Topics" });
            });
          });
        })
        .catch(({ response }) => {
          // store.commit(Mutations.SET_ERROR, [response.data.message]);
        });
    };

    onMounted(() => {
      onGooglePostLogin(route.query.code);
    });

    return {};
  },
});
